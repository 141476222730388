import { render, staticRenderFns } from "./theme-1.vue?vue&type=template&id=1c4ff3fb"
import script from "./theme-1.vue?vue&type=script&lang=js"
export * from "./theme-1.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme1MobileNavBar: require('/app/components/Theme1/Mobile/NavBar.vue').default,Theme1DesktopNavBar: require('/app/components/Theme1/Desktop/NavBar.vue').default,Theme1MobileFooter: require('/app/components/Theme1/Mobile/Footer.vue').default,Theme1DesktopFooter: require('/app/components/Theme1/Desktop/Footer.vue').default,CommonsThemeMode: require('/app/components/Commons/ThemeMode.vue').default})
